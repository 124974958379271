import React, { Component, Dispatch } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// actions
import { actions as authUserActions } from '../../store/reducers/authUser';
// import { thisExpression } from '@babel/types';

interface CustomProps {
  component: any;
  isUserAuthenticated: () => boolean;
  redirectSave: (pathname: string) => void;
  exact?: boolean;
  path?: string;
}

class AuthenticateRoute extends Component<CustomProps> {
  render() {
    const {
      isUserAuthenticated,
      redirectSave,
      component,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          // console.log('props location: ', props, props.location);
          if (!isUserAuthenticated()) {
            redirectSave(props.location.pathname);
          }
          if (isUserAuthenticated()) {
            return <this.props.component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: '/',
              }}
              from={props.location.pathname}
            />
          );
        }}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: () => state.authUser.authToken != null,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  redirectSave: (to: string) => {
    dispatch(authUserActions.redirectSave(to));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateRoute);
