import i18n from 'i18next';
import translationEN from './locales/en/translation.json';
import translationAR from './locales/ar/translation.json';
import { useTranslation, initReactI18next } from 'react-i18next';

// const reactI18nextModule = require('react-i18next');
// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
    react: {
      wait: true,
      // bindStore: false,
    },
    debug: true,
  });

export default i18n;
