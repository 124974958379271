import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ContainerLoader } from '../../components/loader';

import { RouteKeys } from './route-keys';
import AuthenticateRoute from '../auth/AuthenticateRoute';

const Home = lazy(() => import('../home'));
const NotFound = lazy(() => import('../notfound'));
const SearchResult = lazy(() => import('../search-result'));
const Checkout = lazy(() => import('../checkout'));
const MyOrder = lazy(() => import('../my-order'));
const MyAccount = lazy(() => import('../my-account'));
const BannerOffer = lazy(() => import('../banner-offer'));
const PrivacyPolicy = lazy(() => import('../privacy-policy'));
const TermsOfService = lazy(() => import('../terms-of-use'));

class Routes extends React.Component {
  render() {
    return (
      <Suspense fallback={<ContainerLoader height={500} text={'Loading...'} />}>
        <Switch>
          <Route exact path={RouteKeys.Home} component={Home} />
          <Route exact path={RouteKeys.NotFound} component={NotFound} />
          <AuthenticateRoute
            exact
            path={RouteKeys.SearchResult}
            component={SearchResult}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.Checkout}
            component={Checkout}
          />
          <AuthenticateRoute
            // exact
            path={RouteKeys.MyOrder}
            component={MyOrder}
          />
          <AuthenticateRoute path={RouteKeys.MyAccount} component={MyAccount} />

          <AuthenticateRoute
            path={RouteKeys.BannerOffer}
            component={BannerOffer}
          />
          <Route
            exact
            path={RouteKeys.PrivacyPolicy}
            component={PrivacyPolicy}
          />
          <Route
            exact
            path={RouteKeys.TermsOfService}
            component={TermsOfService}
          />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;
