import * as http from './http';
import ApiService from './service';

export default class UserService extends ApiService {
  public async getBanners() {
    const url = `${this.apiDomain}/product/banners/website_id/1/userToken/${
      this.token ? this.token : ''
    }/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/platform/Web/`;

    const response = await http.get(url);
    return { data: response.data };
  }
  public async getQuarterlyPerformance() {
    const url = `${
      this.apiDomain
    }/user/quarterlyperformance/website_id/1/userToken/${
      this.token ? this.token : ''
    }/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/platform/Web/`;

    const response = await http.get(url);
    return { data: response.data };
  }
  public async quarterlyPerformanceJoin(userTargetID: number) {
    const url = `${this.apiDomain}/user/quarterlyperformancejoin`;
    const data = new FormData();

    data.append('userTargetID', userTargetID ? userTargetID.toString() : '');
    data.append('userToken', this.token ? this.token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }
  public async getFAQ() {
    const url = `${this.apiDomain}/faq/website_id/1/userToken/${
      this.token ? this.token : ''
    }/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/platform/Web/`;

    const response = await http.get(url);
    return { data: response.data };
  }

  public async getTerms() {
    const token = this.token ? this.token : '';
    const url = `${
      this.apiDomain
    }/user/gettermsofuse/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/userToken/${token}`;
    return http.get(url, token);
  }

  public async getCategory() {
    const url = `${this.apiDomain}/product/categories/website_id/1/userToken/${
      this.token ? this.token : ''
    }/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/platform/Web/`;

    const response = await http.get(url);
    return { data: response.data };
  }
  public async getLanguages() {
    const url = `${this.apiDomain}/settings/languages/`;
    const response = await http.get(url);
    return { data: response.data };
  }
}
