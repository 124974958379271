import { stringify } from 'query-string';
import * as http from './http';
import ApiService from './service';
import { Product } from '../../models/product';
import { CartItem, CheckoutSubmit } from '../../models';
import { OrderConfirm } from '../../models/order';

const serviceEndpoint = 'order';

export default class OrderService extends ApiService {
  public async getOrderCount(filters?: any) {
    let url = `${
      this.apiDomain
    }/${serviceEndpoint}/ordercount/website_id/1/userToken/${
      this.token
    }/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }`;
    const data = new FormData();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          url = url + `/${key}/${filters[key]}`;
        }
      }
    }
    return http.get(url);
  }
  public async getOrderList(page: number, filters?: any) {
    let url = `${
      this.apiDomain
    }/${serviceEndpoint}/orderlist/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/userToken/${this.token ? this.token : ''}/page/${page}`;
    const data = new FormData();
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          url = url + `/${key}/${filters[key]}`;
        }
      }
    }

    const response = await http.get(url);
    return { data: response.data };
  }

  public async getOrderDetails(orderID: number) {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/orderlist/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/userToken/${this.token ? this.token : ''}/orderID/${orderID}`;

    const response = await http.get(url);
    return { data: response.data };
  }

  public async changeOrderStatus(
    orderID: number,
    status: string,
    reason?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/orderstatus`;
    const data = new FormData();

    data.append('orderID', orderID ? orderID.toString() : '');
    data.append('status', status);
    data.append('reason', reason ? reason : '');
    data.append('userToken', this.token ? this.token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }

  public async highOrderRequest(
    productID: number,
    productOptionID: number,
    orderQty: number
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/highorder`;
    const data = new FormData();

    data.append('productID', productID ? productID.toString() : '');
    data.append(
      'productOptionID',
      productOptionID ? productOptionID.toString() : ''
    );
    data.append('orderQty', orderQty ? orderQty.toString() : '');
    data.append('userToken', this.token ? this.token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }

  public async getOrderFilter() {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/filter/website_id/1/userToken/${
      this.token
    }/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }`;

    return http.get(url);
  }

  public async downloadOrder(orderID: number) {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/orderlist/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/userToken/${
      this.token ? this.token : ''
    }/orderID/${orderID}/download/Yes/`;

    const response = await http.get(url);
    return { data: response.data };
  }

  public async orderConfirm(item: OrderConfirm) {
    const url = `${this.apiDomain}/${serviceEndpoint}/confirm`;
    const data = new FormData();

    data.append('orderID', item.orderID.toString());
    data.append(
      'paymentSettingsGroupKeySelected',
      item.paymentSettingsGroupKeySelected
    );

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }

  public async cancelOrder(orderID: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/cancelOrder`;
    const data = new FormData();

    data.append('orderID', orderID.toString());

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }
}
