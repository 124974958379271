import axios from 'axios';

const useUnauthorizedApiResponseInterceptor = (
  onUnauthorizedResponse?: () => void
) => {
  axios.interceptors.response.use(undefined, (err) => {
    // if (err.response && err.response.status === 401) {
    //   if (onUnauthorizedResponse) {
    //     onUnauthorizedResponse();
    //   }
    // }
    throw err;
  });
};

export default useUnauthorizedApiResponseInterceptor;
