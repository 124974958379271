import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import Banner from '../../models/banner';
import Performance from '../../models/performance';
import Category from '../../models/category';
import { Faq } from '../../models/faq';

export const types = {
  RESET_DATA: 'COMMON/RESET_DATA',

  HOME_BANNER_REQUEST: 'AUTH/HOME_BANNER_REQUEST',
  HOME_BANNER_SUCCESS: 'AUTH/HOME_BANNER_SUCCESS',
  HOME_BANNER_ERROR: 'AUTH/HOME_BANNER_ERROR',

  GET_PERFORMANCE_REQUEST: 'AUTH/GET_PERFORMANCE_REQUEST',
  GET_PERFORMANCE_SUCCESS: 'AUTH/GET_PERFORMANCE_SUCCESS',
  GET_PERFORMANCE_ERROR: 'AUTH/GET_PERFORMANCE_ERROR',

  JOIN_REQUEST: 'CART/JOIN_REQUEST',
  JOIN_SUCCESS: 'CART/JOIN_SUCCESS',
  JOIN_ERROR: 'CART/JOIN_ERROR',

  FAQ_REQUEST: 'AUTH/FAQ_REQUEST',
  FAQ_SUCCESS: 'AUTH/FAQ_SUCCESS',
  FAQ_ERROR: 'AUTH/FAQ_ERROR',

  CATEGORY_REQUEST: 'AUTH/CATEGORY_REQUEST',
  CATEGORY_SUCCESS: 'AUTH/CATEGORY_SUCCESS',
  CATEGORY_ERROR: 'AUTH/CATEGORY_ERROR',

  LANGUAGE_REQUEST: 'AUTH/LANGUAGE_REQUEST',
  LANGUAGE_SUCCESS: 'AUTH/LANGUAGE_SUCCESS',
  LANGUAGE_ERROR: 'AUTH/LANGUAGE_ERROR',
};

export interface CommonState {
  banner?: Banner[];
  isBannerLoading: boolean;
  isBannerLoaded: boolean;
  quarterlyPerformance?: Performance[];
  isPerformanceLoading: boolean;
  isPerformanceLoaded: boolean;
  faq?: Faq[];
  isFaqLoading: boolean;
  isFaqLoaded: boolean;
  categoryList?: Category[];
  isCategoryLoading: boolean;
  isCategoryLoaded: boolean;
  languages?: any[];
  isLanguageLoading: boolean;
  isLanguageLoaded: boolean;
}

const initialState: CommonState = {
  isBannerLoading: false,
  isBannerLoaded: false,
  isPerformanceLoading: false,
  isPerformanceLoaded: false,
  isFaqLoading: false,
  isFaqLoaded: false,
  isCategoryLoading: false,
  isCategoryLoaded: false,
  isLanguageLoading: false,
  isLanguageLoaded: false,
};

export default (
  state: CommonState = initialState,
  action: any
): CommonState => {
  switch (action.type) {
    case types.HOME_BANNER_REQUEST:
      return {
        ...state,
        isBannerLoading: true,
        isBannerLoaded: false,
      };
    case types.HOME_BANNER_SUCCESS:
      return {
        ...state,
        banner: action.data ? action.data.result : [],
        isBannerLoading: false,
        isBannerLoaded: true,
      };
    case types.HOME_BANNER_ERROR:
      return {
        ...state,
        isBannerLoading: false,
        isBannerLoaded: true,
      };
    case types.GET_PERFORMANCE_REQUEST:
      return {
        ...state,
        isPerformanceLoading: true,
        isPerformanceLoaded: false,
      };
    case types.GET_PERFORMANCE_SUCCESS:
      return {
        ...state,
        quarterlyPerformance: action.data ? action.data.result : [],
        isPerformanceLoading: false,
        isPerformanceLoaded: true,
      };
    case types.GET_PERFORMANCE_ERROR:
      return {
        ...state,
        isPerformanceLoading: false,
        isPerformanceLoaded: true,
      };
    case types.JOIN_REQUEST:
      return {
        ...state,
      };
    case types.JOIN_SUCCESS:
      return {
        ...state,
      };
    case types.JOIN_ERROR:
      return {
        ...state,
      };
    case types.FAQ_REQUEST:
      return {
        ...state,
        isFaqLoading: true,
        isFaqLoaded: false,
      };
    case types.FAQ_SUCCESS:
      return {
        ...state,
        faq: action.data ? action.data.result : [],
        isFaqLoading: false,
        isFaqLoaded: true,
      };
    case types.FAQ_ERROR:
      return {
        ...state,
        isFaqLoading: false,
        isFaqLoaded: true,
      };
    case types.CATEGORY_REQUEST:
      return {
        ...state,
        isCategoryLoading: true,
        isCategoryLoaded: false,
      };
    case types.CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: action.data ? action.data.result : [],
        isCategoryLoading: false,
        isCategoryLoaded: true,
      };
    case types.CATEGORY_ERROR:
      return {
        ...state,
        isCategoryLoading: false,
        isCategoryLoaded: true,
      };
    case types.LANGUAGE_REQUEST:
      return {
        ...state,
        isLanguageLoading: true,
        isLanguageLoaded: false,
      };
    case types.LANGUAGE_SUCCESS:
      return {
        ...state,
        languages: action.data ? action.data.result : [],
        isLanguageLoading: false,
        isLanguageLoaded: true,
      };
    case types.LANGUAGE_ERROR:
      return {
        ...state,
        isLanguageLoading: false,
        isLanguageLoaded: true,
      };
    default:
      return state;
  }
};

export const actions = {
  clearData: (onComplete?: () => void) => async (dispatch: Dispatch) => {
    await dispatch({ type: types.RESET_DATA });
    if (onComplete) {
      onComplete();
    }
  },
  getBanners: () => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.HOME_BANNER_REQUEST });
    try {
      const response = await api.common.getBanners();
      const { data } = response;
      dispatch({ type: types.HOME_BANNER_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.HOME_BANNER_ERROR });
      throw error;
    }
  },
  getQuarterlyPerformance: () => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.GET_PERFORMANCE_REQUEST });
    try {
      const response = await api.common.getQuarterlyPerformance();
      const { data } = response;
      dispatch({ type: types.GET_PERFORMANCE_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.GET_PERFORMANCE_ERROR });
      throw error;
    }
  },
  quarterlyPerformanceJoin: (
    userTargetID: number,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.JOIN_REQUEST });
    try {
      const response = await api.common.quarterlyPerformanceJoin(userTargetID);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
      } else {
        dispatch({
          type: types.JOIN_SUCCESS,
          data,
        });
        toastr.success('Success', 'Request posted sucessfully');
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.JOIN_ERROR,
        data: { errorMessage: 'Unable to join' },
      });
    }
  },
  getFAQ: () => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.FAQ_REQUEST });
    try {
      const response = await api.common.getFAQ();
      const { data } = response;
      dispatch({ type: types.FAQ_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.FAQ_ERROR });
      throw error;
    }
  },

  getCategory: () => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.CATEGORY_REQUEST });
    try {
      const response = await api.common.getCategory();
      const { data } = response;
      dispatch({ type: types.CATEGORY_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.CATEGORY_ERROR });
      throw error;
    }
  },
  getLanguages: () => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();

    // if (!state.authUser.authToken) {
    //   return;
    // }
    // api.setToken(state.authUser.authToken);
    // api.setCustomerLanguage(state.authUser.languageID);
    // api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.LANGUAGE_REQUEST });
    try {
      const response = await api.common.getLanguages();
      const { data } = response;
      dispatch({ type: types.LANGUAGE_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.LANGUAGE_ERROR });
      throw error;
    }
  },
};
