import { Dispatch } from 'redux';
import { CartResponse } from '../../models/cart';
import { Product } from '../../models/product';

export const types = {
  RESET_DATA: 'CART/RESET_DATA',

  PRODUCT_SEARCH_REQUEST: 'CART/PRODUCT_SEARCH_REQUEST',
  PRODUCT_SEARCH_SUCCESS: 'CART/PRODUCT_SEARCH_SUCCESS',
  PRODUCT_SEARCH_ERROR: 'CART/PRODUCT_SEARCH_ERROR',
};

export interface SearchState {
  isLoading: boolean;
  isLoaded: boolean;
  searchKey: string;
  category?: any;
  error?: string;
}

const initialState: SearchState = {
  searchKey: '',
  isLoading: false,
  isLoaded: false,
};

export default (
  state: SearchState = initialState,
  action: any
): SearchState => {
  switch (action.type) {
    case types.RESET_DATA:
      return {
        ...initialState,
      };
    case types.PRODUCT_SEARCH_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoading: true,
        isLoaded: false,
      };
    case types.PRODUCT_SEARCH_SUCCESS:
      return {
        ...state,
        searchKey: action.data.searchKey,
        category: action.data.category,
        error: undefined,
        isLoading: false,
        isLoaded: true,
      };
    case types.PRODUCT_SEARCH_ERROR:
      return {
        ...state,
        error: action.data.errorMessage,
        isLoading: false,
        isLoaded: true,
      };
    default:
      return state;
  }
};

export const actions = {
  clearData: (onComplete?: () => void) => async (dispatch: Dispatch) => {
    await dispatch({ type: types.RESET_DATA });
    if (onComplete) {
      onComplete();
    }
  },
  searchProduct: (searchKey?: string, category?: any) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.PRODUCT_SEARCH_REQUEST });
    try {
      dispatch({
        type: types.PRODUCT_SEARCH_SUCCESS,
        data: {
          searchKey,
          category,
        },
      });
    } catch (error) {
      dispatch({
        type: types.PRODUCT_SEARCH_ERROR,
        data: { errorMessage: 'Unable to add cart' },
      });
    }
  },
};
