import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';

import authUser from './reducers/authUser';
import cart from './reducers/cart';
import search from './reducers/search';
import user from './reducers/user';
import product from './reducers/product';
import order from './reducers/order';
import common from './reducers/common';
import checkout from './reducers/checkout';
import notification from './reducers/notification';
import salesperson from './reducers/salesperson';
import bundleOffer from './reducers/bundleOffer';
import { State } from './interfaces';

const rootReducer = combineReducers<State>({
  authUser,
  cart,
  search,
  user,
  product,
  order,
  common,
  checkout,
  notification,
  salesperson,
  bundleOffer,
  toastr: toastrReducer,
  loadingBar: loadingBarReducer,
});

export default rootReducer;
