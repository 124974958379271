import { stringify } from 'query-string';
import {
  CrudDetailsRequestModel,
  CrudListRequestModel,
  CrudRequestModel,
} from '../models';
import * as http from './http';
import ApiService from './service';

export default class CrudService extends ApiService {
  public async getList(request: CrudListRequestModel) {
    // const { page = 1, perPage = 10 } = request.pagination;
    // const { field, order } = request.sort;
    // const query = {
    //   _filters: JSON.stringify(request.filter),
    //   _sort: field,
    //   _order: order,
    //   _start: (page - 1) * perPage,
    //   _end: page * perPage,
    //   ...request.queryParams,
    // };
    const url = `${this.apiDomain}/${request.resource}`;

    const response = await http.get(url);
    return { data: response.data };
  }
}
